<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2 text-danger">
        Parametres du Budget / Axe Budgetaire
      </h6>
    </b-row>
    <b-form>
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group label="" label-for="budgetType" label-class="mb-1">
            <b-form-radio-group
              class="ml-1 list item-view-radio-group"
              button-variant="outline-primary"
              id="budgetType"
              v-model="budgetType"
              :options="budgetComptabilityOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { $themeConfig } from "@themeConfig";
import axios from "axios";
const userData = JSON.parse(localStorage.getItem("userData"));

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    officialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    //
    return {
      sku_corporation: userData.sku_corporation,
      budgetType: "",
      budgetComptabilityOptions: [
        { text: "Axe Analytique", value: "analytique" },
        { text: "Axe Générale", value: "generale" },
        { text: "Aucune", value: "" },
      ],
      errors: [],
    };
  },
  methods: {
    async postCorporationSettingOfficialForm() {
      // for the web app //
      let formData = new FormData();
      formData.append("sku_corporation", this.sku_corporation);
      formData.append("budgetType", this.budgetType);
      //
      await myApi
        .post("update_corporation_infos", formData)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 10000,
              showCancelButton: true,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-warning ml-1",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Erreur Survenu",
              text: response.data.message,
              icon: "error",
              timer: 10000,
              showCancelButton: false,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-warning",
                cancelButton: "btn btn-outline-error ml-1",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: "Erreur Survenu",
            text: error.message,
            icon: "error",
            timer: 10000,
            showCancelButton: false,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-error ml-1",
            },
            buttonsStyling: false,
          });
        });
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    },
  },
};
</script>
